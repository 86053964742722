<template>
  <v-container />
</template>

<script>
  export default {
    created () {
      this.$store.dispatch('auth/logout')
        .then(response => {
          this.$router.push({ name: 'Home' })
        })
    },
  }
</script>
